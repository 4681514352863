.promote-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.promote-modal-container {
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  padding: 0;
  background-color: transparent;
  animation: slideUp 0.3s ease-out;
}

.promote-modal-content {
  padding: 16px;
  border-radius: 16px 16px 0 0;
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}

.promote-modal-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.promote-modal-header {
  font-size: 18px;
  margin: 0;
  color: #1a1a1a;
}

.promote-status-badge {
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
}

.promote-status-badge.promote-eligible {
  background-color: #ecfdf3;
}

.promote-status-badge.promote-ineligible {
  background-color: #fef3f2;
}

.promote-status-text {
  color: #039855;
}

.promote-status-badge.promote-ineligible .promote-status-text {
  color: #d92d20;
}

.promote-modal-subheader-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
  padding: 0 12px;
}

.promote-modal-subheader-container {
  width: 100%;
}

.promote-modal-subheader {
  font-size: 15px;
  color: #374151;
  font-weight: 600;
  background-color: #f8fafc;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.promote-info-item {
  background: #f8fafc;
  padding: 10px 14px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.promote-info-label {
  color: #64748b;
  font-size: 14px;
}

.promote-value-container {
  display: flex;
  align-items: center;
  background-color: rgba(37, 99, 235, 0.1);
  padding: 4px 12px;
  border-radius: 20px;
}

.promote-value-text {
  font-size: 14px;
  font-weight: 500;
  color: #2563eb;
}

.promote-point-icon {
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
}

.promote-editable-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.promote-edit-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promote-slider-container {
  background: #f8fafc;
  padding: 16px;
  border-radius: 8px;
  margin: 8px 0;
}

.promote-slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: #64748b;
  font-size: 12px;
}

.promote-slider-buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 12px;
}

.promote-slider-button {
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.promote-reset-button {
  background-color: #eff6ff;
  border: 1px solid #2563eb;
  color: #2563eb;
}

.promote-done-button {
  background-color: #2563eb;
  color: white;
  border: none;
}

.promote-debit-notice {
  background: linear-gradient(to right, #f8f9ff, #f1f5ff);
  border: 1px solid #e0e7ff;
  padding: 10px 12px;
  border-radius: 12px;
  margin: 16px 0;
}

.promote-debit-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.promote-debit-amount {
  font-size: 16px;
  font-weight: 600;
  color: #2563eb;
}

.promote-debit-text {
  font-size: 14px;
  color: #6b7280;
  font-weight: 500;
}

.promote-required-points-notice {
  background: linear-gradient(135deg, #fff5f5 0%, #fff1f2 100%);
  border: 1px solid #fecdd3;
  padding: 6px;
  border-radius: 12px;
  margin: 16px 0;
  box-shadow: 0 2px 4px rgba(254, 205, 211, 0.1);
}

.promote-required-points-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.promote-required-points-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.promote-amount-badge {
  display: flex;
  align-items: center;
  gap: 6px;
  background: #fff;
  padding: 8px 16px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #fee2e2;
}

.promote-amount-with-point {
  display: flex;
  align-items: center;
  gap: 2px;
}

.promote-amount-value {
  font-size: 18px;
  font-weight: 700;
  color: #ef4444;
  letter-spacing: 0.5px;
}

.promote-required-text {
  margin-left: 4px;
  color: #d92d20;
}

.promote-currency-conversion {
  font-size: 14px;
  color: #f87171;
  font-weight: 500;
  background: rgba(239, 68, 68, 0.08);
  padding: 4px 12px;
  border-radius: 12px;
}

.promote-modal-actions {
  display: grid;
  gap: 10px;
  margin-top: 16px;
}

.promote-add-money-button {
  width: 100%;
  background: linear-gradient(135deg, #059669 0%, #047857 100%);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(5, 150, 105, 0.2);
}

.promote-pay-button {
  width: 100%;
  background: linear-gradient(135deg, #0b59f9 0%, #0043c8 100%);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(11, 89, 249, 0.2);
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promote-pay-button:disabled {
  background: linear-gradient(135deg, #e5e7eb 0%, #d1d5db 100%);
  color: #9ca3af;
  cursor: not-allowed;
  box-shadow: none;
}

.promote-pay-button-text {
  font-weight: 500;
}

.promote-wallet-balance {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 4px 10px;
  border-radius: 6px;
  gap: 4px;
}

.promote-balance-text {
  font-weight: 600;
  font-size: 14px;
  color: white;
}

.promote-cancel-button {
  width: 100%;
  background-color: #fff;
  color: #4b5563;
  border: 1px solid #e5e7eb;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.promote-cancel-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.promote-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.promote-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.rc-slider-track {
  margin-top: 8px;
}

.rc-slider-rail {
  margin-top: 8px;
}

.promote-slider-controls-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
}

.promote-slider-control-button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #2563EB;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.promote-slider-control-button:hover {
  background-color: #1d4ed8;
}

.promote-slider-control-button:active {
  transform: scale(0.95);
}

.promote-slider-control-button-text {
  line-height: 1;
  position: relative;
  top: -1px;
}

.whatsapp-modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 425px;
  width: 100%;
  min-height: 35%;
  margin: 0 auto;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 999999999999;
}

.whatsapp-modal-content {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}

.whatsapp-modal-handle {
width: 50px;
height: 3px;
background-color: lightgray;
border-radius: 5px;
margin-top: 10px;
}

.whatsapp-modal-header {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 15px;
}

.whatsapp-modal-header h2 {
font-weight: 500;
font-size: 20px;
color: #000;
margin: 5px 0;
}

.whatsapp-modal-close-button {
background: none;
border: none;
cursor: pointer;
padding: 5px;
}

.whatsapp-modal-body {
width: 95%;
padding: 15px;
}

.whatsapp-modal-country-picker {
display: flex;
align-items: center;
border: 1px solid #ececf2;
border-radius: 5px;
padding: 10px;
background-color: #f4f7ff;
margin-bottom: 10px;
cursor: pointer;
}

.whatsapp-modal-country-dropdown {
position: absolute;
width: 95%;
max-height: 300px;
overflow-y: auto;
background-color: white;
border: 1px solid #ececf2;
border-radius: 5px;
z-index: 1000;
bottom: 60%;
left: 2.5%;
margin-bottom: 5px;
box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
}

.whatsapp-modal-input-container {
position: relative;
width: 100%;
margin-bottom: 15px;
}

.whatsapp-icon {
position: absolute;
top: 11px;
left: 12px;
color: #000;
}

.whatsapp-icon.focused {
color: #0b59f9;
}

.whatsapp-modal-input-container input {
width: 100%;
height: 40px;
padding: 3px 7px 3px 40px;
font-size: 14px;
font-weight: 600;
border: 1px solid #ececf2;
border-radius: 5px;
background-color: #f4f7ff;
color: #000;
}

.whatsapp-modal-input-container input::placeholder {
color: gray;
}

.whatsapp-modal-input-container input.invalid {
border-color: #ff4d4f;
}

.whatsapp-icon.invalid {
color: #ff4d4f;
}

.error-message {
color: #ff4d4f;
font-size: 12px;
margin-top: 4px;
}

.whatsapp-modal-submit-button {
width: 100%;
background-color: green;
color: white;
border: 1px solid #007bff;
border-radius: 20px;
padding: 10px;
text-align: center;
cursor: pointer;
}

.whatsapp-modal-submit-button:disabled {
opacity: 0.7;
cursor: not-allowed;
}

.whatsapp-modal-country-option {
display: flex;
align-items: center;
gap: 10px;
padding: 10px;
cursor: pointer;
transition: background-color 0.2s;
}

.whatsapp-modal-country-option:hover {
background-color: #f4f7ff;
}

.whatsapp-modal-country-option span:nth-child(2) {
flex: 1;
}

.whatsapp-modal-country-option span:last-child {
color: #666;
}

.whatsapp-modal-search {
position: sticky;
top: 0;
background-color: white;
padding: 10px;
border-bottom: 1px solid #ececf2;
}

.whatsapp-modal-search input {
width: 100%;
padding: 8px 12px;
border: 1px solid #ececf2;
border-radius: 5px;
font-size: 14px;
background-color: #f4f7ff;
}

.whatsapp-modal-search input:focus {
outline: none;
border-color: #0b59f9;
}
.whatsapp-modal-backdrop_effect {
position: fixed;
background: rgb(0 0 0 / 71%);
width: 100%;
height: 100%;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 999;
cursor: pointer;
}

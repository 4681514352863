.slider-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
  }
  
  .dot.active {
    background-color: blue;
  }
  
.flash-news-wrapper {
  margin: 2px 12px;
  margin-bottom: 10px;
}

.carousel-container {
  overflow: visible;
  border-radius: 10px;
  position: relative;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.carousel::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.banner-container {
  flex: 0 0 100%;
  scroll-snap-align: start;
  width: 100%;
}

.flash-news-container {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #4271cc, #2858c6);
  border-radius: 10px;
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  box-shadow: 0 1px 3px rgba(36, 99, 254, 0.15);
  position: relative;
}

.left-section {
  display: flex;
  align-items: center;
  margin-right: 8px;
  position: relative;
}

.flash-news-icon-container {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
  color: #FFFFFF;
}

.live-badge {
  background-color: #FF3D71;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 3px;
  border-radius: 2px;
  position: absolute;
  right: -2px;
  top: 7%;
  transform: translateY(-6px);
  z-index: 2;
}

.live-text {
  color: #FFFFFF;
  font-size: 5px;
  font-weight: bold !important;
  margin-right: 6px;
  /* margin-top: 2px; */
}

.pulse-dot {
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #FFFFFF;
  position: absolute;
  right: 3px;
  top: 50%;
  margin-top: -2px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0.3;
    transform: scale(0.8);
  }
}

.flash-news-text-container {
  flex: 1;
  padding: 2px 0;
  padding-right: 18px;
}

.flash-news-text {
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.flash-news-close-button {
  position: absolute;
  right: -5px;
  top: -7px;
  z-index: 100;
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.close-button-circle {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #F43F5E;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  color: #FFFFFF;
}

.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  margin: 0 2px;
}

.active-dot {
  background-color: #3B82F6;
  width: 12px;
  border-radius: 2.5px;
}

.inactive-dot {
  background-color: rgba(204, 204, 204, 0.7);
}

.flash-news-close-button {
  position: absolute;
  top: 4px;
  right: 6px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  padding: 0;
}

.close-button-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #f43f5e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

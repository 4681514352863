.advertisement-container {
  width: 100%;
  /* padding: 0 2px; */
}

.slider-container {
  /* margin: 5px 0 2px; */
  margin-top: 16px;
  border-radius: 20px;
}

.slider-wrapper {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.slider-wrapper::-webkit-scrollbar {
  display: none;
}

.slider-image-container {
  flex: 0 0 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  /* padding: 0 14px */
  padding: 0 5px;
}

.slider-image-wrapper {
  cursor: pointer;
  width: 100%;

}

.slider-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
}

.login-popup-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999999;
}

.login-popup-modal-container {
  max-width: 360px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* padding: 10px; */
}

.login-popup-modal-content {
  background-color: white;
  border-radius: 14px;
  width: 100%;
  height: 40vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-popup-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: white;
  border: none;
  border-radius: 100%;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transform: translate(30%, -140%);
}

.login-popup-scroll-container {
  width: 95%;
  height: 95%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  /* padding: 8px; */
}

.login-popup-popup-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 100%;
}

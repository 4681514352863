.marquee-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: orange;
    margin-bottom: 10px !important;
    border-radius: 8px;
    width: 96%;
    margin: auto;
    overflow: hidden;
  }
  
  .marquee-icon-container {
    background-color: #FF8C00;
    padding: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .marquee-text-container {
    flex: 1;
    padding: 4px 8px;
    font-weight: 600 !important;
    white-space: nowrap;
    overflow: hidden;
  } 
  
  .marquee-text {
    font-size: 15px;
    color: #FFFFFF !important;
    font-weight: 700 !important;
    white-space: nowrap;
  }
  
  .marquee-close-button {
    background: none;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  
  .marquee-close-button:hover {
    opacity: 0.8;
  }
  
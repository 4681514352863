.btn-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transform: translateY(-1px);
  width: 46%;
  cursor: pointer;
}

.btn-body:hover {
  transform: translateY(-2px);
}

.margin-right {
  margin-right: 15px;
}

.margin-left {
  margin-left: 10px;
}

.button-name {
  color: #333;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin-top: 6px;
}

.icon-container {
  border-radius: 40px;
  padding: 8px;
}


